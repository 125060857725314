<template>
  <div class="g_page_box">
    <div class="g_main_content main_content">
      <img src="../../assets/img/pay_success.png" class="icon" width="153">
      <div class="title">预约成功</div>
      <div class="surplus_time">在临近检查时我们会提醒您</div>
    </div>
    <div class="bottom_box">
      <div class="btn btn-1" @click="to_info">查看提醒</div>
      <div class="btn" @click="to_back">返回首页</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
    }
  },
  computed: {
  },
  components: {
  },
  created () {
  },
  methods: {
    to_back() {
      this.$router.push('/')
    },
    to_info() {
      this.$router.push('/page-alert')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_content {
  text-align: center;
}
.icon {
  margin-top: 65px;
}
.title {
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
  color: #242938;
  margin-top: 28px;
}
.surplus_time {
  font-size: 14px;
  line-height: 20px;
  color: #242938;
  margin-top: 8px;
  & label {
    color: #0088FF;
  }
}
.bottom_box {
  padding: 24px 13px;
  & .btn {
    width: 100%;
    background: #0088FF;
    border-radius: 9px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
  }
  & .btn-1 {
    background: #fff;
    color: #0088FF;
    border: 1px solid #0088FF;
    margin-bottom: 10px;
  }
}
</style>
